import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
@Component({
  selector: 'app-common-left-menu-inner',
  template: require('./common-left-menu-inner.html'),
  styleUrls: [
    '../assets/css/bootstrap.min.css',
    '../assets/lib/owlCarousel/css/animate.css',
    '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    '../assets/css/default2.css',
    '../assets/css/default.css',
    '../assets/css/font.css',
    '../assets/css/default-dashboard.css',
    '../assets/css/dashboard.css',
    '../assets/lib/font-awesome/css/font-awesome.min.css',
    '../assets/css/settings.css',
    // '../assets/css/bootstrap.min.css',
    // '../assets/lib/owlCarousel/css/animate.css',
    // '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    // '../assets/css/default2.css',
    // '../assets/css/default.css',
    // '../assets/css/font.css',
    // '../assets/css/default-dashboard.css',
    // '../assets/css/dashboard.css',
    // '../assets/lib/font-awesome/css/font-awesome.min.css',
    // '../assets/css/settings.css',
   // './app/account/dashboard/common-left-menu-inner.scss'
    'dashboard/common-left-menu-inner.scss'
  ]
})
export class CommonLeftMenuInnerComponent implements OnInit {

  unitInfo: any;
 AuthService;
  Router;
  static parameters = [ AuthService, Router];
  constructor( private authService: AuthService,private router: Router) {     this.Router = router;
    this.AuthService = authService;}

  ngOnInit() {
    this.unitInfo = JSON.parse(localStorage.getItem('user'));
    
    console.log("userInfo-----",this.unitInfo);
  }
  reset(){
    
  }
  logout() {
    return this.AuthService.logout().then(() => {
        this.Router.navigateByUrl('/login');
        localStorage.clear();
        this.reset();
    });
}

}
