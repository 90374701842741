import './app.scss';

import './polyfills';

import { enableProdMode, ViewEncapsulation } from '@angular/core'; 
 import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// depending on the env mode, enable prod mode or add debugging modules
if(process.env.NODE_ENV === 'production') {
    enableProdMode();
}

import { AppModule } from './app.module';
//import { ViewEncapsulation } from '@angular/compiler/src/core';

export function main() {
    // return platformBrowserDynamic().bootstrapModule(AppModule);

    return  platformBrowserDynamic().bootstrapModule(AppModule, [
        {
          defaultEncapsulation: ViewEncapsulation.None
        }
      ]);
}

if(document.readyState === 'complete') {
    main();
} else {
    document.addEventListener('DOMContentLoaded', main);
}
