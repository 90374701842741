import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
//import { DashboardjsService } from '../services/dashboardjs.service';



@Component({
  selector: 'app-dashboard',
  template: require('./dashboard.html'),
  styleUrls: [
    '../assets/css/bootstrap.min.css',
    '../assets/lib/owlCarousel/css/animate.css',
    '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    '../assets/css/default2.css',
    '../assets/css/default.css',
    '../assets/css/font.css',
    '../assets/css/default-dashboard.css',
    '../assets/css/dashboard.css',
    '../assets/lib/font-awesome/css/font-awesome.min.css',
    '../assets/css/settings.css',
    //'./app/account/dashboard/dashboard.scss',
    'dashboard/dashboard.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  
  //providers:[DashboardjsService],
  
})
export class MrdashboardComponent  {
    unitInfo: any;
    LastUploadDate: any;

    datatoday:Date=new Date();
     parentItem = 'lamp';
  // datatoday.to
  static parameters = [HttpClient];

  constructor(private http: HttpClient) {this.http = http; } //private _fun: DashboardjsService

  ngOnInit() {
    this.unitInfo = JSON.parse(localStorage.getItem('user'));
    
    // console.log("userInfo-----",this.unitInfo);
     this.GetLastUploadDate();
    // console.log("GetLastUploadDate-----",this.LastUploadDate);

  } 
//--------add---------//

  ngAfterViewInit(){
    $(document).ready(function(){
    
      $('div[data-id="showrecord"]').hide();

      $('.reportpopup').click(function(){
          $(this).closest('div[data-id="showform"]').slideUp();
          $(this).closest('div[data-id="showform"]').next('div[data-id="showrecord"]').slideDown();
      });
  
      $('.back_parameters').click(function(){
          $(this).closest('div[data-id="showrecord"]').slideUp();
          $(this).closest('div[data-id="showrecord"]').prev('div[data-id="showform"]').slideDown()
  
      });
  
  
    var getWindowWidth = $(window).width();
  if (getWindowWidth <= 1259)
  {
  $(".block-wraper").removeClass("sidebar-min open-menu");
  $("#logo").attr("src", "/assets/images/logo-white.png");
  }
  
  
  
  
  $("#blockToggle").click(function () {
  event.preventDefault();
  var getWindowWidth = $(window).width();

  if (getWindowWidth > 1259) {
      $(".block-wraper").toggleClass("sidebar-min");
      if ($(".block-wraper").hasClass("sidebar-min")) {
          $("#logo").attr("src", "/assets/images/logoSm.png");
      }
      else {
          $("#logo").attr("src", "/assets/images/logo-white.png");
      }
  }
  else if (getWindowWidth <= 1259) {
   // alert();
      $(".block-wraper").toggleClass("block-left-in");
  }

  setTimeout(function () {
      $(".block-wraper").toggleClass("open-menu");
  }, 50);

  
  });
  
  
  $(".block-left").click(function (e) {
  if ($(".droup-menu").hasClass("open-droup")) {
      $(".droup-menu").removeClass('open open-droup');
  }
  e.stopPropagation();
  })
  
  $(document).click(function (e) {
  var container = $(".block-wraper");
  if ($(".block-wraper").hasClass("open-menu")) {
      container.removeClass('block-left-in open-menu');
  }
  });
  
  // for sidemenu max height and for right content min height ...... ...................
  var getUserSectionHeight = $(".block-left-top").height();
  var getToHeaderHeight = $(".block-right-header").height();
  var getFooterHeight = $(".footer").height() + getToHeaderHeight;
  var getWindowHeight = $(window).height();
  var getMenuHeight = getWindowHeight - getUserSectionHeight;
  var getRightContentHeight = getWindowHeight - getFooterHeight;
  $(".block-menu-section").css("max-height", getMenuHeight);
  $(".block-right-content").css("min-height", getRightContentHeight);
  $(window).resize(function () {
  var getUserSectionHeight = $(".block-left-top").height();
  var getToHeaderHeight = $(".block-right-header").height();
  var getFooterHeight = $(".footer").height() + getToHeaderHeight;
  var getWindowHeight = $(window).height();
  var getMenuHeight = getWindowHeight - getUserSectionHeight;
  var getRightContentHeight = getWindowHeight - getFooterHeight;
  $(".block-menu-section").css("max-height", getMenuHeight);
  $(".block-right-content").css("min-height", getRightContentHeight);
  });
  
  //$(window).on("load", function () {
  //    $(".block-menu-section").mCustomScrollbar();
  //});
  
  // Custom droupdown ............... ................
  $(".droup").click(function () {
  event.preventDefault();
  $(this).next(".droup-menu").toggleClass("open");
  //setTimeout(function () {
  //    $(".droup").next(".droup-menu").toggleClass("open-droup");
  //}, 50)
  });
  
  $(".droup-menu").click(function (e) {
  e.stopPropagation();
  })
  $(document).click(function (e) {
  if ($(".droup-menu").hasClass("open")) {
      $(".droup-menu").removeClass('open open-droup');
  }
  });
  
  
  // bank active ...................................................
  $(".bank-active").click(function () {
  if ($(this).hasClass("good"))
  {
      $(this).removeClass("good");
  }
  else
  {
      $(this).toggleClass("good");
      $(this).parent("td").parent("tr").siblings("tr").find(".bank-active").removeClass("good");
  }
  })

    });

  
}
GetLastUploadDate() {
  this.http.get('/api/users/GetLastUploadDate/'+0)
    .subscribe(response => {
    // console.log("LastUploadDate------lk-", response);
       this.LastUploadDate = response[0][0]['LastUploadDate'];
    });
  }  

}
