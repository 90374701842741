// @flow
import { Component, } from '@angular/core';
import{FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';

interface User {
    name: string;
    email: string;
    password: string;
    mobile: string;
    city: string;
    age: string;
    gender:string;
    remarks:string;
}

@Component({
    selector: 'signup',
    template: require('./signup.html'),
    // styleUrls: [
    //     '../assets/css/bootstrap.min.css',
    //     '../assets/lib/owlCarousel/css/animate.css',
    //     '../assets/css/default2.css',
    //     '../assets/css/font.css',
    //     '../assets/css/dashboard.css',
    //     '../assets/lib/font-awesome/css/font-awesome.min.css',
    //     '../assets/css/settings.css',
    //     'signup/singup.scss',
    //     ]
})
export class SignupComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
        mobile: '',
        city: '',
        age: '',
        gender:'',
        remarks:''

    };
    errors: {field?: Error} = {};
    submitted = false;
    AuthService;
    Router;


    static parameters = [AuthService, Router];
    constructor(_AuthService_: AuthService, router: Router) {
        this.AuthService = _AuthService_;
        this.Router = router;    }

        

    register(form) {
        if(form.invalid) return;

        this.submitted = true;

        return this.AuthService.createUser({
            name: this.user.name,
            email: this.user.email,
            password: this.user.password,
            mobile:this.user.mobile,
            city:this.user.city,
            age:this.user.age,
            gender:this.user.gender,
            remarks:this.user.remarks
        })
            .then(() => {
                // Account created, redirect to home
                this.Router.navigateByUrl('/login');
            })
            .catch(err => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    err.fields.forEach(field => {
                        this.errors[field] = err.message;
                    });
                }

                this.submitted = false;
            });
    }

    mr_register = new FormGroup({

        rg_fullname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
       
        rg_email: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+[a-zA-Z0-9-]+[a-zA-Z0-9-]+$')
        ]),
    
        rg_mobile: new FormControl('',[Validators.required, Validators.maxLength(12)]),
    
        rg_city: new FormControl(''),
    
        rg_age: new FormControl(''),
    
        rg_gender: new FormControl(''),
    
        rg_remarks:  new FormControl('',[Validators.required]),
    
      });
    
    
      
    
    
        // register(){   
        //  this.router.navigate(['/mr-login']); 
        // }
    
      get rg_fullname() { return this.mr_register.get('rg_fullname') };
      get rg_email() { return this.mr_register.get('rg_email') };
      get rg_mobile() { return this.mr_register.get('rg_mobile') };
      get rg_city() { return this.mr_register.get('rg_city') };
      get rg_age() { return this.mr_register.get('rg_age') };
      get rg_gender() { return this.mr_register.get('rg_gender') };
      get rg_remarks() { return this.mr_register.get('rg_remarks') };
       
}

