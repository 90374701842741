import { Component } from '@angular/core';
import * as $ from 'jquery';
@Component({
    selector: 'app',
    template: `<navbar></navbar>
    <router-outlet></router-outlet>
    <footer></footer>`
})
export class AppComponent {

    ngOnInit() {
    }
}
