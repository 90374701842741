// @flow
import { Component } from '@angular/core';
import{FormControl, FormGroup, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import * as $ from 'jquery';

interface User {
    name: string;
    email: string;
    password: string;
    company: string;
}

@Component({
    selector: 'login',
    template: require('./login.html'),

    // styleUrls: ['./login.scss'],
    // styleUrls: [
    //     '../login/login.scss',
    //     '../assets/css/default2.css',
    //     '../assets/css/font.css',
    //     '../assets/lib/font-awesome/css/font-awesome.min.css',
    //      ]
})
export class LoginComponent {
    [x: string]: any;
    user: User = {
        name: '',
        email: '',
        password: '',
        company:'',
    };
    errors = {login: undefined};
    submitted = false;
    AuthService;
    Router;
    firmname: Object;

    static parameters = [AuthService, Router,HttpClient];
    constructor(_AuthService_: AuthService, router: Router,private http: HttpClient) {
        this.AuthService = _AuthService_;
        this.Router = router;
        this.http = http;

    }
	
	 ngOnInit() {
		   $(function(){
			   
			   $('input[type="email"]').focus();
        }); 
        
         this.get_allfirm_Name();
		 
	 }

    login(form) {
        if(form.invalid) return;
       // console.log("this.user-------",this.user);
        return this.AuthService.login({
            email: this.user.email,
            password: this.user.password,
            company: this.user.company,
        })
            .then((response) => {
               // console.log("response.................................",response);
                  if (typeof response === 'undefined'){
                 // console.log("response11111...................",response);
                  this.Router.navigateByUrl('/login');
                  this.errors.login = 'The firm is not correct';
                  }else{
                  // Logged in, redirect to home
                  this.Router.navigateByUrl('/dashboard');
                }
                

            })
            .catch(err => {
                 //console.log("erryrshrhtr---------------------------",err);
                this.errors.login = err.error.message;
            });
            
    }
    mr_login = new FormGroup({

        lo_username: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+[a-zA-Z0-9-]+[a-zA-Z0-9-]+$')
        ]),
       
        lo_password: new FormControl('', [Validators.required]),
    
        lo_company: new FormControl('', [Validators.required]),

        lo_keep: new FormControl(''), 
    
      });
     get lo_username() { return this.mr_login.get('lo_username') };
      get lo_password() { return this.mr_login.get('lo_password') };  
      get lo_company() {return this.mr_login.get('lo_company')};
      get lo_keep() { return this.mr_login.get('lo_keep') };

      mr_forgot = new FormGroup({

        fg_email: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+[a-zA-Z0-9-]+[a-zA-Z0-9-]+$')
        ])   
      
      });
      
      
      forgot(){  
        console.log(this.mr_login.value); 
         this.router.navigateByUrl(['/home']); 
      }
      
      get fg_email() { return this.mr_forgot.get('fg_email') };  
      
      // ==================forgot validations============
      
      // ==================jquery============
        gotoLinkForget(){
          $(".block-form").hide();
          $(".block-forgot").show();
        }
      
        gotoSignIn(){
        $(".block-forgot").hide();
        $(".block-form").show();
        }
      // ==================jquery============
      
      get_allfirm_Name() {
        this.http.get('/api/companys/get_firm_Name/'+0)
          .subscribe(response => {
            //console.log("company list-------", response);
            this.firmname = response;
          });
      }
      
      }

