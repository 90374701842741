import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import 'core-js/es7/reflect';
//import {DashboardjsService} from '../services/dashboardjs.service' 
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormsModule, Validators, FormControl, FormArray, NgForm } from '@angular/forms';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { YEAR, MONTH, DATE } from 'ngx-bootstrap/chronos/units/constants';
//import { Date } from 'core-js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { autoTable as AutoTable } from 'jspdf-autotable';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-dealerwisesale',
  template: require('./dealerwisesale.html'),
  styleUrls: [
    '../assets/css/bootstrap.min.css',
    '../assets/lib/owlCarousel/css/animate.css',
    '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    '../assets/css/default2.css',
    '../assets/css/default.css',
    '../assets/css/font.css',
    '../assets/css/default-dashboard.css',
    '../assets/css/dashboard.css',
    '../assets/lib/font-awesome/css/font-awesome.min.css',
    '../assets/css/settings.css', 
    '../assets/css/theme.css',
    '../assets/css/primeng.min.css',
    '../assets/css/primeicons.css',

    'dashboard/dealerwisesale.scss',
    `.custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
  `],
    
  // providers:[DashboardjsService]
})
export class DealerwisesaleComponent implements OnInit {
  public form: FormGroup;

  addForm: FormGroup;
  rows: FormArray;
  itemForm: FormGroup;


  
 // new
 companyAbr: any;
 divisionAbr:any;
 locationAbr:any;
 reportname:any;
 date : Date = new Date();
 today=this.date.getDate();
 month=1+this.date.getMonth();
 year=this.date.getFullYear();

 cal1Date: Date=new Date(); 
 newdate0: Date=new Date();
 getdate1(){
  var date = new Date();
      date = new Date(date.getFullYear(), date.getMonth(), 1); 
  return date;
}
newdate1: Date = this.getdate1();


getdate2(){
  var date = new Date();
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate()); 
  return date;
}
newdate2: Date = this.getdate2();
 
 onStart() {  
  // console.log('-----------', this.newdate1);
  this.cal1Date;
 }

 clearDateRangePicker(dateRangeSel) {
   let val=dateRangeSel.toString();
   let arr;
 
   arr= val.split(",");
  
   this.newdate1=new Date(arr[0]);
	if(arr[1]){
    this.newdate2=new Date(arr[1]);  
	}

  // console.log('1111', this.newdate1.getDay()); 
  // console.log('2222', this.newdate2);
  }
  
// new

 
  page: any = 1;
  pageSize: any = 25;
  collectionSize: any = 0;
  dataBinging: any;
  row:string;
  length: object;
  itemwise: any;
  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;
  dealerwise: any;
  dealerwise_view_for_mail: any;
  dealerwisesale={
    T_Date:'',
  };
  dealer: Object;
  company: Object;
  Division: Object;
  unitInfo: any;

  Item_ID_filter: string;
  City_ID_Filter: string;

  dd_dealer: string;
  dd_area: string;

  cols: any[];
  Dealer_List: any[];
  Area_List: any[];

  f_check_string(p_value) {
    if (p_value == undefined || p_value === '' || p_value === null || p_value !== p_value) {
        return "";
    } else {
        return p_value;
    }
  }
  f_check_number(p_value) {
      if (p_value == undefined || p_value === '' || p_value === null || p_value !== p_value) {
          return 0;
      } else {
          return Number(p_value);
      }
  }
  
  [x: string]: Object;

  static parameters = [HttpClient, NgbCalendar,FormBuilder,BsModalService];
  public items: any;

  modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    

  }
  

  constructor(private http: HttpClient, public calendar: NgbCalendar ,private fb: FormBuilder ,private modalService: BsModalService) {

    console.log('this.fb----',this.fb);
    this.addForm = this.fb.group({
      items: ['yes'],
      items_value: ['yes'],
       mremail :[null],
       comment :[null]
     
    });
  
    this.rows = this.fb.array([]);
    this.fb = fb;

    this.http = http;
   this.fromDate 	= Object.assign({},calendar.getToday());		//console.log(JSON.stringify(calendar.getToday()));
   // this.fromDate = Object.assign({},  this.fromDate.year+'-'+ this.fromDate.month+'-'+ this.fromDate.day);	
    this.toDate 	= Object.assign({},calendar.getToday());			//console.log(JSON.stringify(calendar.getToday()));
    //this.toDate 	= Object.assign({},calendar.getNext(calendar.getToday(), 'd', 10));
    // this._fun = _fun;
    

    this.cols = [
      { field: 'Dealer', header: 'Dealer' },
      { field: 'Area', header: 'City' },
      { field: 'Sr_CSV', header: 'Sr' },
      { field: 'ItemName', header: 'Item Name' },
      { field: 'Packing', header: 'Packing' },
      { field: 'SalesQty', header: 'Qty' },
      { field: 'Amount', header: 'Amount' }
    ];

    this.dealerwise = [];
    this.dealerwise_view_for_mail = [];
   }

   
   onDateSelection(date: NgbDate) {
    if (!this.fromDate) {
		this.fromDate = Object.assign({},date);
    }else if (!this.toDate) {
		this.toDate = Object.assign({},date);
	} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
		this.toDate = Object.assign({},date);
    } else {
		this.toDate = null;
		this.fromDate = Object.assign({},date);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  ngOnInit() {

    this.addForm.get("items_value").setValue("yes");
    this.addForm.addControl('rows', this.rows);

    this.onStart();
    
     $(function(){
      $('.ui-dropdown-trigger').find('span').removeAttr('class').attr('class', 'glyphicon glyphicon-chevron-down');

      $('.ui-dropdown-trigger').prev('i').removeAttr('class').attr('class', 'glyphicon glyphicon-remove-circle');
	  
	  $('.ui-paginator-first').find('span').removeAttr('class').attr('class', 'glyphicon  glyphicon-fast-backward');
	  
	  $('.ui-paginator-prev').find('span').removeAttr('class').attr('class', 'glyphicon  glyphicon-backward');
	  
	  $('.ui-paginator-next').find('span').removeAttr('class').attr('class', 'glyphicon  glyphicon-forward');
	  
	  $('.ui-paginator-last').find('span').removeAttr('class').attr('class', 'glyphicon  glyphicon-fast-forward');
	  
	  
    }); 
   // this.getDealerWiseSalesList();
   // this.get_company_Name();
    this.unitInfo = JSON.parse(localStorage.getItem('user'));
    this.fromDate.day = 1;
	this.company = [ 
						{	
							"Company" : this.unitInfo.Company,	
							"CompanyCode" : this.unitInfo.Code 
						}
					];
	 this.Division = [ 
						{	
							"Division" : this.unitInfo.Division
						}
					];
    
    console.log("userInfo-----",this.unitInfo);
	this.companyAbr=''+this.unitInfo.Company.substring(0,3);
	this.divisionAbr=''+this.unitInfo.Division.substring(0,3);
	this.locationAbr=''+this.unitInfo.LocNo.toString();
	this.locationAbr=this.locationAbr.substring(0,2);
	this.reportname='DS_ASH_BR_'+this.newdate1.getDate()+(1+this.newdate1.getMonth())+'_'+this.newdate2.getDate()+(1+this.newdate2.getMonth())+'_'+this.companyAbr+'_'+this.divisionAbr+'.csv';
  }
  ngAfterViewInit(){
    $(document).ready(function(){

      
	 $('.close_modal').click(function(){
    //alert();
    $('#emailReportModal').removeClass('in show');
  });
  
  $('.show_email_modal').click(function(){
    //alert();
    $('#emailReportModal').addClass('in show');
  });
  
    $('#addMoreEmail').click(function(){ 
    $('#emailReportModal').addClass('in show');
    
    //var rowtext=`<div class="col-xs-12"><div class="form-group"><label>MR Email<span class="mendatory">*</span></label><input class="form-control" type="text" value=""><span class="block-error"></span></div></div>`;
    
    //$('.showemail').before(rowtext);
  });
  
                  // tab code
                  var page;
                  var  url= window.location.pathname;
                  var  urlarr=url.split('/');
                       if(urlarr.length==2){
                        page=urlarr[1];
                       }
                       else if(urlarr.length==3){
                        page=urlarr[2];
                       } 
                  console.log('page: ',page, urlarr); 
                  $("li[data-val="+page+"]").addClass('active');
                  // tab code
     // $('#date1').val('2019-06-01');
    
      $('div[data-id="showrecord"]').hide();

      $('.reportpopup').click(function(){
          $(this).closest('div[data-id="showform"]').slideUp();
          $(this).closest('div[data-id="showform"]').next('div[data-id="showrecord"]').slideDown();
      });
  
      $('.back_parameters').click(function(){
          $(this).closest('div[data-id="showrecord"]').slideUp();
          $(this).closest('div[data-id="showrecord"]').prev('div[data-id="showform"]').slideDown()
  
      });
  
  
    var getWindowWidth = $(window).width();
  if (getWindowWidth <= 1259)
  {
  $(".block-wraper").removeClass("sidebar-min open-menu");
  $("#logo").attr("src", "/assets/images/logo-white.png");
  }
  
  
  
  
  $("#blockToggle").click(function () {
  event.preventDefault();
  var getWindowWidth = $(window).width();
  if (getWindowWidth > 1259) {
      $(".block-wraper").toggleClass("sidebar-min");
      if ($(".block-wraper").hasClass("sidebar-min")) {
          $("#logo").attr("src", "/assets/images/logoSm.png");
      }
      else {
          $("#logo").attr("src", "/assets/images/logo-white.png");
      }
  }
  else if (getWindowWidth <= 1259) {
      $(".block-wraper").toggleClass("block-left-in");
  }
  setTimeout(function () {
      $(".block-wraper").toggleClass("open-menu");
  }, 50);
  
  });
  
  
  $(".block-left").click(function (e) {
  if ($(".droup-menu").hasClass("open-droup")) {
      $(".droup-menu").removeClass('open open-droup');
  }
  e.stopPropagation();
  })
  
  $(document).click(function (e) {
  var container = $(".block-wraper");
  if ($(".block-wraper").hasClass("open-menu")) {
      container.removeClass('block-left-in open-menu');
  }
  });
  
  // for sidemenu max height and for right content min height ...... ...................
  var getUserSectionHeight = $(".block-left-top").height();
  var getToHeaderHeight = $(".block-right-header").height();
  var getFooterHeight = $(".footer").height() + getToHeaderHeight;
  var getWindowHeight = $(window).height();
  var getMenuHeight = getWindowHeight - getUserSectionHeight;
  var getRightContentHeight = getWindowHeight - getFooterHeight;
  $(".block-menu-section").css("max-height", getMenuHeight);
  $(".block-right-content").css("min-height", getRightContentHeight);
  $(window).resize(function () {
  var getUserSectionHeight = $(".block-left-top").height();
  var getToHeaderHeight = $(".block-right-header").height();
  var getFooterHeight = $(".footer").height() + getToHeaderHeight;
  var getWindowHeight = $(window).height();
  var getMenuHeight = getWindowHeight - getUserSectionHeight;
  var getRightContentHeight = getWindowHeight - getFooterHeight;
  $(".block-menu-section").css("max-height", getMenuHeight);
  $(".block-right-content").css("min-height", getRightContentHeight);
  });
  
  //$(window).on("load", function () {
  //    $(".block-menu-section").mCustomScrollbar();
  //});
  
  // Custom droupdown ............... ................
  $(".droup").click(function () {
  event.preventDefault();
  $(this).next(".droup-menu").toggleClass("open");
  //setTimeout(function () {
  //    $(".droup").next(".droup-menu").toggleClass("open-droup");
  //}, 50)
  });
  
  $(".droup-menu").click(function (e) {
  e.stopPropagation();
  })
  $(document).click(function (e) {
  if ($(".droup-menu").hasClass("open")) {
      $(".droup-menu").removeClass('open open-droup');
  }
  });
  
  
  // bank active ...................................................
  $(".bank-active").click(function () {
  if ($(this).hasClass("good"))
  {
      $(this).removeClass("good");
  }
  else
  {
      $(this).toggleClass("good");
      $(this).parent("td").parent("tr").siblings("tr").find(".bank-active").removeClass("good");
  }
  })

  
    });


}

 /*  getDealerWiseSalesList(salesForm){
    this.fromDate, this.toDate
    let from =  this.fromDate.year+'-'+ this.fromDate.month+'-'+ this.fromDate.day;
    let todte =  this.toDate.year+'-'+ this.toDate.month+'-'+ this.toDate.day;
	let Firmno = !!this.unitInfo.FirmNo ? this.unitInfo.FirmNo : null;
    let Locno = !!this.unitInfo.LocNo ? this.unitInfo.LocNo : null;
    let Code = !!this.unitInfo.Code ? this.unitInfo.Code : null;
    let Division = !!this.unitInfo.Division ? this.unitInfo.Division : null;

    this.http.get('/api/dealers/' +from +'/'+ todte+'/'+ Firmno +'/'+ Locno +'/'+ Code+'/'+ Division )
      .subscribe(response => {
        console.log("response12345abcdef-------", response);
        this.dataBinging = response;
        if(response){
          this.collectionSize =  this.dataBinging.length;
          this.dealerData();
        }
      })
    
  } */
  
	getDealerWiseSalesList(salesForm){
    $('.block-right-content').append('<div class="loadingdiv"><span>Loading...</span></div>');
    this.dd_dealer = "";
    this.dd_area = "";

		let v_data = 
		{
			// "from_date": this.fromDate.year+'-'+ this.fromDate.month+'-'+ this.fromDate.day,
      // "to_date": this.toDate.year+'-'+ this.toDate.month+'-'+ this.toDate.day,
      "from_date": this.newdate1.getFullYear()+'-'+ (1+this.newdate1.getMonth())+'-'+this.newdate1.getDate(),
      "to_date": this.newdate2.getFullYear()+'-'+ (1+this.newdate2.getMonth())+'-'+this.newdate2.getDate(),
			 "Firmno": !!this.unitInfo.FirmNo ? this.unitInfo.FirmNo : null,
			 "Locno": !!this.unitInfo.LocNo ? this.unitInfo.LocNo : null,
			 "Code": !!this.unitInfo.Code ? this.unitInfo.Code : null,
			 "Division": !!this.unitInfo.Division ? this.unitInfo.Division : null
		};

			//this.http.get('/api/dealers/' +from +'/'+ todte+'/'+ Firmno +'/'+ Locno +'/'+ Code+'/'+ Division )
		this.http.post('/api/dealers/getDealerWiseSales', v_data)
		.subscribe(response => {
      console.log("response abcdef-------", response);
      $('.block-right-content').find('.loadingdiv').remove();
      //this.dataBinging = response;
      this.dealerwise = response['DealerData'];

      this.dealerwise_view_for_mail = response['DealerData_CSV'];
      this.Dealer_List = response['DealerList'];
      this.Area_List = response['AreaList'];

      /* if (response) {
        this.collectionSize = this.dataBinging.length;
        this.dealerData();
      } */
				
		})
  }
  
  getDealerWiseSalesList_Filter(p_dealer,p_area) {
    $('.block-right-content').append('<div class="loadingdiv"><span>Loading...</span></div>');
    p_dealer = this.f_check_string(p_dealer);
    p_area = this.f_check_string(p_area);

    let v_data = 
		{ 
     // "from_date": this.fromDate.year+'-'+ this.fromDate.month+'-'+ this.fromDate.day,
     // "to_date": this.toDate.year+'-'+ this.toDate.month+'-'+ this.toDate.day,
     "from_date": this.newdate1.getFullYear()+'-'+ (1+this.newdate1.getMonth())+'-'+this.newdate1.getDate(),
     "to_date": this.newdate2.getFullYear()+'-'+ (1+this.newdate2.getMonth())+'-'+this.newdate2.getDate(),
      "Firmno": !!this.unitInfo.FirmNo ? this.unitInfo.FirmNo : null,
      "Locno": !!this.unitInfo.LocNo ? this.unitInfo.LocNo : null,
      "Code": !!this.unitInfo.Code ? this.unitInfo.Code : null,
      "Division": !!this.unitInfo.Division ? this.unitInfo.Division : null,
      "Dealer": p_dealer,
      "Area": p_area
		};
    //v_data = {"from_date":"2019-6-1","to_date":"2019-6-26","Firmno":11,"Locno":11,"Code":"ABBOTT","Division":"ABBOTT - CONSUMER CARE","Dealer":p_dealer,"Area":p_area,};
   
    this.http.post('/api/dealers/getDealerWiseSales_Filter', v_data)
      .subscribe(response => {
        console.log("getDealerWiseSales_Filter : response", response);
        $('.block-right-content').find('.loadingdiv').remove();
        this.dealerwise = response;

      })
  }



  DealerTimeout: any;
  onDealerFilter(event, dt) {     
    console.log(JSON.stringify(dt._value));
    if (this.DealerTimeout) {
        clearTimeout(this.DealerTimeout);
    }

    this.DealerTimeout = setTimeout(() => {
      //dt.filter(event.value, 'Area', 'contains');
      this.getDealerWiseSalesList_Filter(event.value,this.dd_area || '');
      console.log(JSON.stringify(dt._value));

    }, 250);
  }

  AreaTimeout: any;
  onAreaFilter(event, dt) {     
    console.log(JSON.stringify(dt._value));
    if (this.AreaTimeout) {
        clearTimeout(this.AreaTimeout);
    }

    this.AreaTimeout = setTimeout(() => {
      //dt.filter(event.value, 'Area', 'contains');
      this.getDealerWiseSalesList_Filter(this.dd_dealer || '',event.value);
      console.log(JSON.stringify(dt._value));

    }, 250);
  }


  dealerData() {
    this.dealerwise = this.dataBinging
      .map((item, i) => ({id: i + 1, ...item}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  get_company_Name() {
    this.http.get('/api/companys')
      .subscribe(response => {
        //console.log("company list-------", response);
        this.company = response;
      });
  }
  onItemChange(event) {
    if(event != undefined && event != null ){
      this.Item_ID_filter = event.ItemName;  
    } else {
      this.Item_ID_filter =  "";
    }
    console.log(this.Item_ID_filter);
    
  }
  onAreaChange(event) {
    if(event != undefined && event != null ){
      this.City_ID_Filter = event.Area;  
    } else {
      this.City_ID_Filter =  "";
    }
    console.log(this.City_ID_Filter);
  }
  downloadPDF(event) { 
    //just added console.log which will display the event details in browser on click of the button.
    //alert("Button is clicked");
    console.log(event);
    var doc = new jsPDF();
    ((doc as any).autoTable as AutoTable)({html:"#dealerreport"});
    doc.save("ImageSample.pdf")
    
 }
 isavailable = true;
  // emailReport(event) {
  //   if (this.dealerwise_view_for_mail.length == 0)
  //   alert("Button is clicked");
    // {  
    //   return false;
    // }

    //this.dealerwise_view_for_mail = [{"a": "b","b": "c"},{"a": "b1","b": "c1"}];

  //   this.http.post('/api/dealers/sendEmailWithAttachments', this.dealerwise_view_for_mail)
  //   .subscribe(response => {
  //     console.log(JSON.stringify(response));
  //   })
  // }

  onAddRow() {
    this.rows.push(this.createItemFormGroup());
  }

  onRemoveRow(rowIndex:number){
    this.rows.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({
      name: null,
     
    });
  }
  emailReport() {
	  if (this.dealerwise_view_for_mail.length == 0)
    {  
      return false;
    }
    //console.log(this.form.value);
    var mremail = this.addForm.value.mremail;
    var comment = this.addForm.value.comment;
    var moreemail = this.addForm.value.rows;
    
    let v_mail_data = 
		{ 
      "mremail": !!mremail ? mremail : null,
      "comment": !!comment ? comment : null,
      "moreemail": !!moreemail ? moreemail : null,
	   "Full_Name": this.unitInfo.Full_Name,
	  "dealerwise": this.dealerwise_view_for_mail
    };
    
  //  if (v_mail_data.length == 0){

      this.http.post('/api/dealers/sendEmailWithAttachments', v_mail_data)
      .subscribe(response => {
       // console.log(JSON.stringify(response));
      ;

       $('#show_success_msg').removeClass('hide');
       setTimeout(function(){
         $('button.close').trigger('click');
       },3000); 
       this.addForm.reset({ mremail: this.unitInfo.email});
      })
  //  }
    console.log("form value",mremail,comment,moreemail);
  }
}
