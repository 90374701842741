import { Component, OnInit } from '@angular/core';


import{FormControl, FormGroup, Validators} from '@angular/forms';
import * as $ from 'jquery';



@Component({
    selector: 'app-home',
    template: require('./main.html'),
    styleUrls: [
    '../assets/css/bootstrap.min.css',
    '../assets/lib/owlCarousel/css/animate.css',
    '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    '../assets/css/default2.css',
    '../assets/css/font.css',
    '../assets/css/dashboard.css',
    '../assets/lib/font-awesome/css/font-awesome.min.css',
    '../assets/css/settings.css',
    //'./app/account/home/main.scss',
    'home/main.scss'
  ]
  })

//'./app/account/home/main.scss']
//'home/main.scss']

export class HomeComponent implements OnInit {

    constructor() { }
  
    ngOnInit() {
     $(function(){

      $(".navbar-toggle").click(function () {
        event.preventDefault(); 
            $(this).toggleClass('active');
            $(".navbar-collapse").toggleClass("collapse"); 
           // $(".navbar-collapse").find('a').click(function(){
             // $(".navbar-toggle").click();
            //});
        });



      // alert('hi');
      $('.gotoabout').attr('href','#whatWeOffer');
      $('.gotoreach').attr('href','#ourReach');
      $('.gotocontact').attr('href','#contact');
  
      $(".gotoabout").on('click', function (event) {
        event.preventDefault();
   
              var hash = '#whatWeOffer';
             $('html, body').animate({
                scrollTop: $(hash).offset().top,
             }, 800, function () {
    
            });
    
    });
  
    $(".gotoreach").on('click', function (event) {
      event.preventDefault();
      var hash = '#ourReach';
      $('html, body').animate({
         scrollTop: $(hash).offset().top,
      }, 800, function () {
  
     });
  });
  
  $(".gotocontact").on('click', function (event) {
    event.preventDefault();
    var hash = '#contact';
    $('html, body').animate({
       scrollTop: $(hash).offset().top,
    }, 800, function () {
  
   });
  });
  
  
  $(".goToTop").on('click', function (event) {
    event.preventDefault();
    var hash = '#home';
    $('html, body').animate({
       scrollTop: $(hash).offset().top,
    }, 800, function () {
  
   });
  });
  
   
  
  
  
      
  
  
      var win = $(window);
  
      var allMods = $(".animate-up");
      
      // allMods.each(function (i, el) {
      //     var el = $(el);
      //     if (el.visible(true)) {
      //         el.addClass("already-visible");
      //     }
      // });
      
      // win.scroll(function (event) {
      
      //     allMods.each(function (i, el) {
      //         var el = $(el);
      //         if (el.visible(true)) {
      //             el.addClass("come-in");
      //         }
      //     });
      
      // });
  
      
     });
    }
  
    carouselOptions = {
      margin: 0,
      nav: false,
      navText: [],
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 1,
          nav: true
        },
        1000: {
          items: 1,
          nav: true,
          loop: true
        },
        1500: {
          items: 1,
          nav: false,
          loop: true,
          dots:true
        }
      }
    }
  
  // ======================
  
  contact = new FormGroup({
  
    co_name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
   
    co_email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+[a-zA-Z0-9-]+[a-zA-Z0-9-]+$')
    ]),
  
    co_mobile: new FormControl('',[Validators.required, Validators.maxLength(12)]),
  
    co_message: new FormControl('',[Validators.required]),
  
   
  
  });
  
  
  
  
  
  submitContact(){   
  
  console.log(this.contact.value);
      
    }
  
  get co_name() { return this.contact.get('co_name') };
  get co_email() { return this.contact.get('co_email') };
  get co_mobile() { return this.contact.get('co_mobile') };
  get co_message() { return this.contact.get('co_message') }; 
  
   
  
  //========================
  
  }
  