import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-common-left-menu',
  template: require('./common-left-menu.html'),
  styleUrls: [
    // '../assets/css/bootstrap.min.css',
    // '../assets/lib/owlCarousel/css/animate.css',
    // '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    // '../assets/css/default2.css',
    // '../assets/css/default.css',
    // '../assets/css/font.css',
    // '../assets/css/default-dashboard.css',
    // '../assets/css/dashboard.css',
    // '../assets/lib/font-awesome/css/font-awesome.min.css',
    // '../assets/css/settings.css',
    'dashboard/common-left-menu.scss'
  ]
})
export class CommonLeftMenuComponent implements OnInit {

  AuthService;
  Router;
  static parameters = [ AuthService, Router];
  unitInfo: any;
  constructor( private authService: AuthService,private router: Router) {
    this.Router = router;
    this.AuthService = authService;
   }

  ngOnInit() {
    this.unitInfo = JSON.parse(localStorage.getItem('user'));
    
    console.log("userInfo-----",this.unitInfo);
  }
  reset() {
    // this.unitInfo = JSON.parse(localStorage.getItem('permission'))[0];
    // console.log('this.unitInfo:updated', this.unitInfo);
    // this.AuthService.isLoggedIn().then(is => {
    //     this.isLoggedIn = is;
    //     this.unitInfo = JSON.parse(localStorage.getItem('permission'))[0];
    //     //console.log(localStorage.getItem('permission'));
    // });
}

  logout() {
    return this.AuthService.logout().then(() => {
        this.Router.navigateByUrl('/login');
        localStorage.clear();
        this.reset();
    });
}



}
