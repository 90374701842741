import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, FormsModule, Validators, FormControl, FormArray, NgForm } from '@angular/forms';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import * as Highcharts from 'highcharts';
import { formatDate } from '@angular/common';
//import { DashboardjsService } from '../services/dashboardjs.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

interface item {
  FirmNo: string,
  LocNo: string,
  Code: string,
  Division: string,
  T_Date: string,
  Doctor: string,
  City: string,
  InvNo: string,
  InvDt: string,
  InvAmt: string,
}
const SALES: item[] = [];

declare var require: any;

import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { YEAR, MONTH, DATE } from 'ngx-bootstrap/chronos/units/constants';
//import { Date } from 'core-js';
import { request } from 'http';

@Component({
  selector: 'app-salesstock',
  template: require('./salesstock.html'),
  styleUrls: [
    '../assets/css/bootstrap.min.css',
    '../assets/lib/owlCarousel/css/animate.css',
    '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    '../assets/css/default.css',
    '../assets/css/default2.css',
    '../assets/css/font.css',
    '../assets/css/dashboard.css',
    '../assets/css/default-dashboard.css',
    '../assets/lib/font-awesome/css/font-awesome.min.css',
    '../assets/css/settings.css',
    //'./app/account/dashboard/salesstock.scss',
      'dashboard/salesstock.scss',
    '../assets/css/theme.css',
    '../assets/css/primeng.min.css',
    '../assets/css/primeicons.css',
    `.custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
  `],

  //providers: [DashboardjsService]

})

export class SalesstockComponent implements OnInit {
  public form: FormGroup;

  addForm: FormGroup;
  rows: FormArray;
  itemForm: FormGroup; 
  // new
  dataPDF: any;
  date: Date = new Date();
  today = this.date.getDate();
  month = 1 + this.date.getMonth();
  year = this.date.getFullYear();

  cal1Date: Date = new Date();
  newdate0: Date = new Date();
  // newdate1: Date = new Date(this.year + '-' + this.month + '-1'); 
  getdate1(){
    var date = new Date();
        date = new Date(date.getFullYear(), date.getMonth(), 1); 
    return date;
  }
  newdate1: Date = this.getdate1();
  
 
  getdate2(){
    var date = new Date();
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate()); 
    return date;
  }
  newdate2: Date = this.getdate2();
  //newdate2: Date = new Date(this.year + '-' + this.month + '-' + this.today);

  onStart() {
    // console.log('-----------', this.newdate1);
    this.cal1Date;
    //$('.ui-datepicker-group,.ng-trigger-overlayAnimation').show();
  }

  clearDateRangePicker(dateRangeSel) {
    let val = dateRangeSel.toString();
    let arr;

    arr = val.split(",");

    this.newdate1 = new Date(arr[0]);
    if (arr[1]) {
      this.newdate2 = new Date(arr[1]); 
      // $('.ui-datepicker-group,.ng-trigger-overlayAnimation').hide();
    }

   
    // console.log('1111', this.newdate1.getDay()); 
    // console.log('2222', this.newdate2);
  }

  // new


  page: any = 1;
  pageSize: any = 25;
  collectionSize: any = 0;
  StockSales_List: Object;
  // sales: Object;
  length: object;
  sstocksalesArray;
  hoveredDate: NgbDate;
  fromDate: Date;

  toDate: Date;
  currentdate: Date;
  sSOpenngTotal: any;
  sSReceiptTotal: any;
  sSOutwardSalesTotal: any;
  sSClosingTotal: any;

  rangeDates: any;


  /*   StockSales_list: Object;
    Products without Stock_list: Object;
    PurchaseBills_list: Object;
    CreditNoteClaimPending_list: Object; */

  company: Object;
  division: Object;
  unitInfo: any;
  value: string;
  [x: string]: Object;

  highcharts = Highcharts;
  chartOptions = {
    chart: {
      type: 'column'
    },
    title: false,
    subtitle: false,
    xAxis: {
      type: 'category',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    yAxis: false,
    legend: false,

    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        colorByPoint: true,
        colors: ['#f7a35c', '#e4d354', '#434348', '#7cb5ec']
      }
    },
    tooltip: {
      pointFormat: '{point.y:.1f}'
    },
    series: [{
      name: '',
      data: [
        ['Opening', 15492],
        ['Receipt', 16266],
        ['Sales', 14130],
        ['Closing', 17628],

      ],

      dataLabels: {
        enabled: false,
        rotation: -90,
        color: '#FFFFFF',
        align: 'right',
        format: '{point.y:.1f}', // one decimal
        y: 10, // 10 pixels down from the top
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }

    }]
  };


  f_check_string(p_value) {
    if (p_value == undefined || p_value === '' || p_value === null || p_value !== p_value) {
      return "";
    } else {
      return p_value;
    }
  }
  f_check_number(p_value) {
    if (p_value == undefined || p_value === '' || p_value === null || p_value !== p_value) {
      return 0;
    } else {
      return Number(p_value);
    }
  }


  static parameters = [HttpClient, NgbCalendar, FormBuilder, BsModalService];
  public items: any;
  modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  constructor(private http: HttpClient, public calendar: NgbCalendar, private fb: FormBuilder, private modalService: BsModalService) {
    console.log('', calendar);
    this.addForm = this.fb.group({
      items: ['yes'],
      items_value: ['yes'],
      mremail: [null],
      comment: [null]

    });

    this.rows = this.fb.array([]);
    this.fb = fb;
    this.http = http;

    this.currentdate = this.newdate1;
    // this.fromDate = calendar.getToday();

    // this.fromDate.getDate()=1;
    //this.toDate 	= Object.assign({},calendar.getToday());

    this.fromDate = this.newdate1;
    this.toDate = this.newdate2;




  }

  f_load_chart(p_data) {
    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: false,
      subtitle: false,
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif'
          }
        }
      },
      yAxis: false,
      legend: false,

      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          colorByPoint: true,
          colors: ['#f7a35c', '#e4d354', '#434348', '#7cb5ec']
        }
      },
      tooltip: {
        pointFormat: '{point.y:.1f}'
      },
      series: [{
        name: '',
        data: p_data,
        dataLabels: {
          enabled: false,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.1f}', // one decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif'
          }
        }

      }]
    };
  }


  /*  onDateSelection(date: NgbDate) {
 
    
 
     if (!this.fromDate && !this.toDate) {
       this.fromDate = date;
     } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
       this.toDate = date;
     } else {
       this.toDate = null;
       this.fromDate = date;
     }
   }
 
   isHovered(date: NgbDate) {
     return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
   }
 
   isInside(date: NgbDate) {
     return date.after(this.fromDate) && date.before(this.toDate);
   }
 
   isRange(date: NgbDate) {
     return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
   }
  */
  ngOnInit() {
    this.addForm.get("items_value").setValue("yes");
    this.addForm.addControl('rows', this.rows);

    this.onStart(); // new

    // console.log('Rahul', this.fromDate);
    // this.fromDate.getDate()=1; //new

    this.unitInfo = JSON.parse(localStorage.getItem('user'));
    this.fromDate = this.newdate1;
    this.company = [
      {
        "Company": this.unitInfo.Company,
        "CompanyCode": this.unitInfo.Code
      }
    ];
    this.division = [
      {
        "Division": this.unitInfo.Division
      }
    ];
    this.value = 'mrp';
    // this.get_company_Name();
    // console.log("userInfo-----",this.unitInfo);
    // console.log(this.company, 'company');
    // console.log("userInfo-----SNS",this.unitInfo);
    //	this.get_company_Name();

    this.StockSales_List = {
      "StockSales": [],
      "ProductsWOStock": [],
      "PurchaseBills": [],
      "CreditNoteClaimPending": [],
      "StockSales_Summary": [],
      "PurchaseBills_Summary": []
    }

  }


  ngAfterViewInit() {
    

    this.fromDate = this.newdate1; 
   
    $(document).ready(function () {
      this.fromDate = this.newdate1;  
      // tab code
      var page;
      var url = window.location.pathname;
      var urlarr = url.split('/');
      if (urlarr.length == 2) {
        page = urlarr[1];
      }
      else if (urlarr.length == 3) {
        page = urlarr[2];
      }
      console.log('page: ', page, urlarr);
      $("li[data-val=" + page + "]").addClass('active');
      // tab code


      $('div[data-id="showrecord"]').hide();

      $('.reportpopup').click(function () {
        $(this).closest('div[data-id="showform"]').slideUp();
        $(this).closest('div[data-id="showform"]').next('div[data-id="showrecord"]').slideDown();
      });

      $('.back_parameters').click(function () {
        $(this).closest('div[data-id="showrecord"]').slideUp();
        $(this).closest('div[data-id="showrecord"]').prev('div[data-id="showform"]').slideDown()

      });


      var getWindowWidth = $(window).width();
      if (getWindowWidth <= 1259) {
        $(".block-wraper").removeClass("sidebar-min open-menu");
        $("#logo").attr("src", "/assets/images/logo-white.png");
      }




      $("#blockToggle").click(function () {
        event.preventDefault();
        var getWindowWidth = $(window).width();
        if (getWindowWidth > 1259) {
          $(".block-wraper").toggleClass("sidebar-min");
          if ($(".block-wraper").hasClass("sidebar-min")) {
            $("#logo").attr("src", "/assets/images/logoSm.png");
          }
          else {
            $("#logo").attr("src", "/assets/images/logo-white.png");
          }
        }
        else if (getWindowWidth <= 1259) {
          $(".block-wraper").toggleClass("block-left-in");
        }
        setTimeout(function () {
          $(".block-wraper").toggleClass("open-menu");
        }, 50);

      });


      $(".block-left").click(function (e) {
        if ($(".droup-menu").hasClass("open-droup")) {
          $(".droup-menu").removeClass('open open-droup');
        }
        e.stopPropagation();
      })

      $(document).click(function (e) {
        var container = $(".block-wraper");
        if ($(".block-wraper").hasClass("open-menu")) {
          container.removeClass('block-left-in open-menu');
        }
      });

      // for sidemenu max height and for right content min height ...... ...................
      var getUserSectionHeight = $(".block-left-top").height();
      var getToHeaderHeight = $(".block-right-header").height();
      var getFooterHeight = $(".footer").height() + getToHeaderHeight;
      var getWindowHeight = $(window).height();
      var getMenuHeight = getWindowHeight - getUserSectionHeight;
      var getRightContentHeight = getWindowHeight - getFooterHeight;
      $(".block-menu-section").css("max-height", getMenuHeight);
      $(".block-right-content").css("min-height", getRightContentHeight);
      $(window).resize(function () {
        var getUserSectionHeight = $(".block-left-top").height();
        var getToHeaderHeight = $(".block-right-header").height();
        var getFooterHeight = $(".footer").height() + getToHeaderHeight;
        var getWindowHeight = $(window).height();
        var getMenuHeight = getWindowHeight - getUserSectionHeight;
        var getRightContentHeight = getWindowHeight - getFooterHeight;
        $(".block-menu-section").css("max-height", getMenuHeight);
        $(".block-right-content").css("min-height", getRightContentHeight);
      });

      //$(window).on("load", function () {
      //    $(".block-menu-section").mCustomScrollbar();
      //});

      // Custom droupdown ............... ................
      $(".droup").click(function () {
        event.preventDefault();
        $(this).next(".droup-menu").toggleClass("open");
        //setTimeout(function () {
        //    $(".droup").next(".droup-menu").toggleClass("open-droup");
        //}, 50)
      });

      $(".droup-menu").click(function (e) {
        e.stopPropagation();
      })
      $(document).click(function (e) {
        if ($(".droup-menu").hasClass("open")) {
          $(".droup-menu").removeClass('open open-droup');
        }
      });


      // bank active ...................................................
      $(".bank-active").click(function () {
        if ($(this).hasClass("good")) {
          $(this).removeClass("good");
        }
        else {
          $(this).toggleClass("good");
          $(this).parent("td").parent("tr").siblings("tr").find(".bank-active").removeClass("good");
        }
      })


    });


  }

  /* 	getsalesstockList1(salesForm) {
    this.fromDate, this.toDate
    let from =  this.fromDate.year+'-'+ this.fromDate.month+'-'+ this.fromDate.getDate();
    let todte =  this.toDate.year+'-'+ this.toDate.month+'-'+ this.toDate.getDate();
    let Firmno = !!this.unitInfo.FirmNo ? this.unitInfo.FirmNo : null;
    let Locno = !!this.unitInfo.LocNo ? this.unitInfo.LocNo : null;
    let Code = !!this.unitInfo.Code ? this.unitInfo.Code : null;
    let Division = !!this.unitInfo.Division ? this.unitInfo.Division : null;
  
    this.http.get('/api/saless/' +from +'/'+ todte+'/'+ Firmno +'/'+ Locno +'/'+ Code+'/'+ Division +'/'+ this.value)
    .subscribe(response => {
    console.log("response1234-------", response);
    this.dataBinging = response;
    if(response){
    this.collectionSize =  this.dataBinging.length;
    this.salesData();
    }
    })
    }
   */
  getsalesstockList(salesForm) {
    $('.block-right-content').append('<div class="loadingdiv"><span>Loading...</span></div>');
    let v_data =
    {
      "from_date": this.newdate1.getFullYear() + '-' + (1 + this.newdate1.getMonth()) + '-' + this.newdate1.getDate(),
      "to_date": this.newdate2.getFullYear() + '-' + (1 + this.newdate2.getMonth()) + '-' + this.newdate2.getDate(),
      //	"to_date": this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.getDate(),
      "Firmno": !!this.unitInfo.FirmNo ? this.unitInfo.FirmNo : null,
      "Locno": !!this.unitInfo.LocNo ? this.unitInfo.LocNo : null,
      "Code": !!this.unitInfo.Code ? this.unitInfo.Code : null,
      "Division": !!this.unitInfo.Division ? this.unitInfo.Division : null,
      "value": this.value
    };

    this.http.post('/api/saless/getsalesandstock', v_data)
      .subscribe(response => {
       // console.log(JSON.stringify(response));
        $('.block-right-content').find('.loadingdiv').remove();
        this.StockSales_List = response;

        var v_Opening = this.f_check_number(this.StockSales_List['StockSales_Summary'][0].Opening);
        var v_Inward = this.f_check_number(this.StockSales_List['StockSales_Summary'][0].Inward);
        var v_Outward = this.f_check_number(this.StockSales_List['StockSales_Summary'][0].Outward);
        var v_Closing = this.f_check_number(this.StockSales_List['StockSales_Summary'][0].Closing);

        var v_data = [
          ['Opening', v_Opening],
          ['Receipt', v_Inward],
          ['Sales', v_Outward],
          ['Closing', v_Closing]
        ];

        this.f_load_chart(v_data);



        //this.sstocksalesArray = this.StockSales_List[0].StockSales;

        // for (var i=0;i<this.sstocksalesArray.length;i++)
        // {
        //   var words = this.sstocksalesArray[i].ItemName.split(" ");
        //   var words2 = this.sstocksalesArray[i+1].ItemName.split(" ");
        //   if (words[0] === words2[0]){
        //     this.groupofLists.push(this.sstocksalesArray[i])
        //   }
        // }

      })

  }


  salesData() {
    //this.StockSales_list = this.dataBinging.StockSales;
    //.map((item, i) => ({id: i + 1, ...item}))
    //.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  get_company_Name() {
    this.http.get('/api/companys')
      .subscribe(response => {
        console.log("company list-------", response);

        // this.company = response;

        this.company = [this.unitInfo.Company, this.unitInfo.Division];

      });
  }
  emailReport_old(event) {
    //just added console.log which will display the event details in browser on click of the button.
    //alert("Button is clicked");
    //console.log(event);

    let v_data =
    {
      //	"from_date": this.fromDate.year+'-'+ this.fromDate.month+'-'+ this.fromDate.getDate(),
      //	"to_date": this.toDate.year+'-'+ this.toDate.month+'-'+ this.toDate.getDate(),
      "from_date": this.newdate1.getFullYear() + '-' + (1 + this.newdate1.getMonth()) + '-' + this.newdate1.getDate(),
      "to_date": this.newdate2.getFullYear() + '-' + (1 + this.newdate2.getMonth()) + '-' + this.newdate2.getDate(),
      "Firmno": !!this.unitInfo.FirmNo ? this.unitInfo.FirmNo : null,
      "Locno": !!this.unitInfo.LocNo ? this.unitInfo.LocNo : null,
      "Code": !!this.unitInfo.Code ? this.unitInfo.Code : null,
      "Division": !!this.unitInfo.Division ? this.unitInfo.Division : null,
      "value": this.value
    };
    //console.log('before execution');
    this.http.post('/api/saless/getsalesandstock', v_data)
      .subscribe(response => {
       // console.log(JSON.stringify(response));
        this.StockSales_List = response;
      })
    //console.log('after execution');

    this.http.post('/api/saless/sendEmailWithAttachments', this.StockSales_List)
      .subscribe(response => {
      //  console.log(JSON.stringify(response));
        var resp = response;
        console.log('resp', resp);
        return resp;
      })
  }

  // emailReport(event) {
  //   var pdf = new jsPDF();
  //   var heading = 'Ashok Pharma, B/S SANSTHA VASAHAT GATE PRATAP ROAD, RAOPURA, BARODA.- 390 001';
  //   pdf.setFontSize(12);
  //   pdf.text("Ashok Pharma, B/S SANSTHA VASAHAT GATE PRATAP ROAD, RAOPURA, BARODA.- 390 001", 12, 10);
  //   pdf.setFontSize(10);
  //   pdf.text('Monthly Stock and Sales Statement', 12, 15);
  //   pdf.text('Period -' + this.newdate1.getDate() + '/' + (1 + this.newdate1.getMonth()) + '/' + this.newdate1.getFullYear() + ' and ' + this.newdate2.getDate() + '/' + (1 + this.newdate2.getMonth()) + '/' + this.newdate2.getFullYear(), 12, 20);
  //   pdf.text('Report generated at ' + formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en'), 12, 25);
  //   pdf.autoTable({ html: '#my-table' });
  //   pdf.autoTable({ html: '#my-table1' });
  //   pdf.autoTable({ html: '#my-table2' });
  //   pdf.autoTable({ html: '#my-table3' });

  //   this.dataPDF = pdf.output();
  //   //console.log('this.dataPDF',this.dataPDF);
  //   var v_data = { "pdf_data": this.dataPDF };

  //   this.http.post('/api/saless/sendEmailWithAttachments', v_data)
  //     .subscribe(response => {
  //       console.log(JSON.stringify(response));
  //       var resp = response;
  //       console.log('resp', resp);
  //     })
  // }

  downloadpdf(event) {
//console.log(event);
    const doc = new jsPDF();
    var heading = 'Ashok GNXT Pharma, B/S SANSTHA VASAHAT GATE PRATAP ROAD, RAOPURA, BARODA.- 390 001';
    doc.setFontSize(12);
    doc.text("Ashok GNXT Pharma, B/S SANSTHA VASAHAT GATE PRATAP ROAD, RAOPURA, BARODA.- 390 001", 12, 10);
    doc.setFontSize(10);
    doc.text('Monthly Stock and Sales Statement', 12, 15);
    // doc.text('Period -'+ this.fromDate.getDate()+'/'+this.fromDate.getMonth()+'/'+this.fromDate.getFullYear()+' and '+this.toDate.getDate()+'/'+this.toDate.getMonth()+'/'+this.toDate.getFullYear(),12,20);
    doc.text('Period -' + this.newdate1.getDate() + '/' + (1 + this.newdate1.getMonth()) + '/' + this.newdate1.getFullYear() + ' and ' + this.newdate2.getDate() + '/' + (1 + this.newdate2.getMonth()) + '/' + this.newdate2.getFullYear(), 12, 20);
    doc.text('Report generated at ' + formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en'), 12, 25);
    // console.log(formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en'));
    //console.log('currentdate', this.currentdate.getDate()+this.currentdate.month+this.currentdate.year);
    doc.autoTable({ margin: { top: 35, right: 14, bottom: 20, left: 14 }, html: '#my-table' });
    doc.autoTable({ html: '#my-table1' });
    doc.autoTable({ html: '#my-table2' });
    doc.autoTable({ html: '#my-table3' });
    var companyAbr = '' + this.unitInfo.Company.substring(0, 3);
    var divisionAbr = '' + this.unitInfo.Division.substring(0, 3);
    var reportname = 'SS_ASH_BR_' + this.newdate1.getDate() + (1 + this.newdate1.getMonth()) + '_' + this.newdate2.getDate() + (1 + this.newdate2.getMonth()) + '_' + companyAbr + '_' + divisionAbr;
    doc.save(reportname + '.pdf');

  }
  isavailable = true;
  onAddRow() {
    this.rows.push(this.createItemFormGroup());
  }

  onRemoveRow(rowIndex:number){
    this.rows.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({
      name: null,
     
    });
  }
  emailReport() {
	  
	  const doc = new jsPDF();
    var heading = 'Ashok GNXT Pharma, B/S SANSTHA VASAHAT GATE PRATAP ROAD, RAOPURA, BARODA.- 390 001';
    doc.setFontSize(12);
    doc.text("Ashok GNXT Pharma, B/S SANSTHA VASAHAT GATE PRATAP ROAD, RAOPURA, BARODA.- 390 001", 12, 10);
    doc.setFontSize(10);
    doc.text('Monthly Stock and Sales Statement', 12, 15);
    doc.text('Period -' + this.newdate1.getDate() + '/' + (1 + this.newdate1.getMonth()) + '/' + this.newdate1.getFullYear() + ' and ' + this.newdate2.getDate() + '/' + (1 + this.newdate2.getMonth()) + '/' + this.newdate2.getFullYear(), 12, 20);
    doc.text('Report generated at ' + formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en'), 12, 25);
    doc.autoTable({ margin: { top: 35, right: 14, bottom: 20, left: 14 }, html: '#my-table' });
    doc.autoTable({ html: '#my-table1' });
    doc.autoTable({ html: '#my-table2' });
    doc.autoTable({ html: '#my-table3' });
   

  var pdfBase64 = doc.output('datauristring');
    //console.log(this.form.value);
    var mremail = this.addForm.value.mremail;
    var comment = this.addForm.value.comment;
    var moreemail = this.addForm.value.rows;
    console.log('pdfBase64', pdfBase64);
    let v_mail_data = 
		{ 
      "mremail": !!mremail ? mremail : null,
      "comment": !!comment ? comment : null,
      "moreemail": !!moreemail ? moreemail : null,
	  "Full_Name": this.unitInfo.Full_Name,
	   "pdfBase64":pdfBase64
    };
    this.http.post('/api/saless/sendEmailWithAttachments', v_mail_data)
       .subscribe(response => {
        $('#show_success_msg').removeClass('hide');
        setTimeout(function(){
          $('button.close').trigger('click');
        },3000); 

        this.addForm.reset({ mremail: this.unitInfo.email});
      })
  //  }
    console.log("form value",mremail,comment,moreemail);
}
}
