import { Injectable } from '@angular/core';
import * as $ from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class DashboardjsService {

  constructor() { } 


  set2(){
    $("#blockToggle").click(function () {
        event.preventDefault();
        var getWindowWidth = $(window).width();
        if (getWindowWidth > 1259) {
            $(".block-wraper").toggleClass("sidebar-min");
            if ($(".block-wraper").hasClass("sidebar-min")) {
                $("#logo").attr("src", "/assets/images/logoSm.png");
            }
            else {
                $("#logo").attr("src", "/assets/images/logo-white.png");
            }
        }
        else if (getWindowWidth <= 1259) {
            $(".block-wraper").toggleClass("block-left-in");
        }
        setTimeout(function () {
            $(".block-wraper").toggleClass("open-menu");
        }, 50);
        
        });
  }

 set(){

    $('div[data-id="showrecord"]').hide();

    $('.reportpopup').click(function(){
        $(this).closest('div[data-id="showform"]').slideUp();
        $(this).closest('div[data-id="showform"]').next('div[data-id="showrecord"]').slideDown();
    });

    $('.back_parameters').click(function(){
        $(this).closest('div[data-id="showrecord"]').slideUp();
        $(this).closest('div[data-id="showrecord"]').prev('div[data-id="showform"]').slideDown()

    });


  var getWindowWidth = $(window).width();
if (getWindowWidth <= 1259)
{
$(".block-wraper").removeClass("sidebar-min open-menu");
$("#logo").attr("src", "/assets/images/logo-white.png");
}







$(".block-left").click(function (e) {
if ($(".droup-menu").hasClass("open-droup")) {
    $(".droup-menu").removeClass('open open-droup');
}
e.stopPropagation();
})

$(document).click(function (e) {
var container = $(".block-wraper");
if ($(".block-wraper").hasClass("open-menu")) {
    container.removeClass('block-left-in open-menu');
}
});

// for sidemenu max height and for right content min height ...... ...................
var getUserSectionHeight = $(".block-left-top").height();
var getToHeaderHeight = $(".block-right-header").height();
var getFooterHeight = $(".footer").height() + getToHeaderHeight;
var getWindowHeight = $(window).height();
var getMenuHeight = getWindowHeight - getUserSectionHeight;
var getRightContentHeight = getWindowHeight - getFooterHeight;
$(".block-menu-section").css("max-height", getMenuHeight);
$(".block-right-content").css("min-height", getRightContentHeight);
$(window).resize(function () {
var getUserSectionHeight = $(".block-left-top").height();
var getToHeaderHeight = $(".block-right-header").height();
var getFooterHeight = $(".footer").height() + getToHeaderHeight;
var getWindowHeight = $(window).height();
var getMenuHeight = getWindowHeight - getUserSectionHeight;
var getRightContentHeight = getWindowHeight - getFooterHeight;
$(".block-menu-section").css("max-height", getMenuHeight);
$(".block-right-content").css("min-height", getRightContentHeight);
});

//$(window).on("load", function () {
//    $(".block-menu-section").mCustomScrollbar();
//});

// Custom droupdown ............... ................
$(".droup").click(function () {
event.preventDefault();
$(this).next(".droup-menu").toggleClass("open");
//setTimeout(function () {
//    $(".droup").next(".droup-menu").toggleClass("open-droup");
//}, 50)
});

$(".droup-menu").click(function (e) {
e.stopPropagation();
})
$(document).click(function (e) {
if ($(".droup-menu").hasClass("open")) {
    $(".droup-menu").removeClass('open open-droup');
}
});


// bank active ...................................................
$(".bank-active").click(function () {
if ($(this).hasClass("good"))
{
    $(this).removeClass("good");
}
else
{
    $(this).toggleClass("good");
    $(this).parent("td").parent("tr").siblings("tr").find(".bank-active").removeClass("good");
}
})
 
}

}
