import { NgModule } from '@angular/core';
import { FormsModule ,  ReactiveFormsModule} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent }  from './app.component';
import 'core-js/es7/reflect';
//import { AccountModule }  from './account/account.component';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/main.component';
import { LoginComponent } from './login/login.component';
//import { LogoutComponent} from './logout/logout.component';
import { SettingsComponent } from './settings/settings.component';
import { SignupComponent } from './signup/signup.component';
import { CommonLeftMenuComponent } from './dashboard/common-left-menu.component';
import { MrdashboardComponent } from './dashboard/dashboard.component';
import { CommonLeftMenuInnerComponent } from './dashboard/common-left-menu-inner.component';
import { MroutstandingComponent } from './dashboard/outstanding.component';
import { SalesstockComponent } from './dashboard/salesstock.component';
import { DealerwisesaleComponent } from './dashboard/dealerwisesale.component';
import { ItemwisesaleComponent } from './dashboard/itemwisesale.component';
import { from } from 'rxjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartComponent } from "highcharts-angular";
import { NgSelectModule } from '@ng-select/ng-select';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';

import { FilterPipe } from './validation/filter.pipe';
import {CalendarModule} from 'primeng/calendar';

import { ModalModule } from 'ngx-bootstrap/modal';

//import { TableModule } from 'primeng/table';
//import { DropdownModule } from 'primeng/dropdown';

const accountRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    // {
    //     path: 'logout',
    //     component: LogoutComponent,
    // },   
    {
        path: 'settings',
        component: SettingsComponent,
    }, {
        path: 'signup',
        component: SignupComponent,
    }, {
        path: 'dashboard',
        children: [
            { path: '', component: MrdashboardComponent },
            { path: '', component: CommonLeftMenuInnerComponent },
            { path: 'outstanding', component: MroutstandingComponent },
            { path: 'sales-n-stock', component: SalesstockComponent },
            { path: 'dealerwise-sales', component: DealerwisesaleComponent },
            { path: 'itemwise-sales', component: ItemwisesaleComponent },
        ]

    },
];

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        RouterModule.forChild(accountRoutes),
        NgbModule.forRoot(),
        NgSelectModule, 
		TableModule,
        DropdownModule,
        ModalModule.forRoot(),
        CalendarModule
    ],
    exports: [   
			TableModule,
			DropdownModule
    ],
    declarations: [
        HomeComponent,
        LoginComponent,
        //LogoutComponent,
        SignupComponent,
        SettingsComponent,
        CommonLeftMenuComponent,
        MrdashboardComponent,
        MroutstandingComponent,
        CommonLeftMenuInnerComponent,
        SalesstockComponent,
        DealerwisesaleComponent,
        ItemwisesaleComponent,
        HighchartsChartComponent,
        FilterPipe,
    ],
     //bootstrap: [AppComponent]
})
export class AccountModule {
  
 }
