import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { DashboardjsService } from '../services/dashboardjs.service';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';

interface item {
  FirmNo: string,
  LocNo: string,
  Code: string,
  Division: string,
  T_Date: string,
  Doctor: string,
  City: string,
  InvNo: string,
  InvDt: string,
  InvAmt: string,
}
const OUTSTANDING: item[] = [];

@Component({
  selector: 'app-outstanding',
  template: require('./outstanding.html'),
  styleUrls: [
    '../assets/css/bootstrap.min.css',
    '../assets/lib/owlCarousel/css/animate.css',
    '../assets/lib/owlCarousel/css/owl.carousel.min.css',
    '../assets/css/default2.css',
    '../assets/css/default.css',
    '../assets/css/font.css',
    '../assets/css/default-dashboard.css',
    '../assets/css/dashboard.css',
    '../assets/lib/font-awesome/css/font-awesome.min.css',
    '../assets/css/settings.css',
    //'./app/account/dashboard/outstanding.scss',
      'dashboard/outstanding.scss'
  ],

  providers: [DashboardjsService]
})
export class MroutstandingComponent implements OnInit {


  page: any = 1;
  pageSize: any = 25;
  collectionSize: any = 0;
  dataBinging: any;
  outstanding: any = [];
  outstanding_summary: any = [];
  length: object;
  company: object;



  [x: string]: Object;

  static parameters = [HttpClient];

  constructor(private http: HttpClient, private _fun: DashboardjsService) {

    this.http = http;
    this._fun = _fun;
  }

  ngOnInit() {
    this.unitInfo = JSON.parse(localStorage.getItem('user'));
    this.company = {
      Company: this.unitInfo.Company,
      Division: this.unitInfo.Division
    }
    this.getoutstandingList();
  }


  ngAfterViewInit() {
    $(document).ready(function () {

      // tab code
      var page;
      var url = window.location.pathname;
      var urlarr = url.split('/');
      if (urlarr.length == 2) {
        page = urlarr[1];
      }
      else if (urlarr.length == 3) {
        page = urlarr[2];
      }
      console.log('page: ', page, urlarr);
      $("li[data-val=" + page + "]").addClass('active');
      // tab code

      $('div[data-id="showrecord"]').hide();

      $('.reportpopup').click(function () {
        $(this).closest('div[data-id="showform"]').slideUp();
        $(this).closest('div[data-id="showform"]').next('div[data-id="showrecord"]').slideDown();
      });

      $('.back_parameters').click(function () {
        $(this).closest('div[data-id="showrecord"]').slideUp();
        $(this).closest('div[data-id="showrecord"]').prev('div[data-id="showform"]').slideDown()

      });


      var getWindowWidth = $(window).width();
      if (getWindowWidth <= 1259) {
        $(".block-wraper").removeClass("sidebar-min open-menu");
        $("#logo").attr("src", "/assets/images/logo-white.png");
      }




      $("#blockToggle").click(function () {
        event.preventDefault();
        var getWindowWidth = $(window).width();
        if (getWindowWidth > 1259) {
          $(".block-wraper").toggleClass("sidebar-min");
          if ($(".block-wraper").hasClass("sidebar-min")) {
            $("#logo").attr("src", "/assets/images/logoSm.png");
          }
          else {
            $("#logo").attr("src", "/assets/images/logo-white.png");
          }
        }
        else if (getWindowWidth <= 1259) {
          $(".block-wraper").toggleClass("block-left-in");
        }
        setTimeout(function () {
          $(".block-wraper").toggleClass("open-menu");
        }, 50);

      });


      $(".block-left").click(function (e) {
        if ($(".droup-menu").hasClass("open-droup")) {
          $(".droup-menu").removeClass('open open-droup');
        }
        e.stopPropagation();
      })

      $(document).click(function (e) {
        var container = $(".block-wraper");
        if ($(".block-wraper").hasClass("open-menu")) {
          container.removeClass('block-left-in open-menu');
        }
      });

      // for sidemenu max height and for right content min height ...... ...................
      var getUserSectionHeight = $(".block-left-top").height();
      var getToHeaderHeight = $(".block-right-header").height();
      var getFooterHeight = $(".footer").height() + getToHeaderHeight;
      var getWindowHeight = $(window).height();
      var getMenuHeight = getWindowHeight - getUserSectionHeight;
      var getRightContentHeight = getWindowHeight - getFooterHeight;
      $(".block-menu-section").css("max-height", getMenuHeight);
      $(".block-right-content").css("min-height", getRightContentHeight);
      $(window).resize(function () {
        var getUserSectionHeight = $(".block-left-top").height();
        var getToHeaderHeight = $(".block-right-header").height();
        var getFooterHeight = $(".footer").height() + getToHeaderHeight;
        var getWindowHeight = $(window).height();
        var getMenuHeight = getWindowHeight - getUserSectionHeight;
        var getRightContentHeight = getWindowHeight - getFooterHeight;
        $(".block-menu-section").css("max-height", getMenuHeight);
        $(".block-right-content").css("min-height", getRightContentHeight);
      });

      //$(window).on("load", function () {
      //    $(".block-menu-section").mCustomScrollbar();
      //});

      // Custom droupdown ............... ................
      $(".droup").click(function () {
        event.preventDefault();
        $(this).next(".droup-menu").toggleClass("open");
        //setTimeout(function () {
        //    $(".droup").next(".droup-menu").toggleClass("open-droup");
        //}, 50)
      });

      $(".droup-menu").click(function (e) {
        e.stopPropagation();
      })
      $(document).click(function (e) {
        if ($(".droup-menu").hasClass("open")) {
          $(".droup-menu").removeClass('open open-droup');
        }
      });


      // bank active ...................................................
      $(".bank-active").click(function () {
        if ($(this).hasClass("good")) {
          $(this).removeClass("good");
        }
        else {
          $(this).toggleClass("good");
          $(this).parent("td").parent("tr").siblings("tr").find(".bank-active").removeClass("good");
        }
      })

    });


  }
  unitInfo: any;

  getoutstandingList() {

    this.unitInfo = JSON.parse(localStorage.getItem('user'));
    let Code = this.unitInfo.Code;
    let LocNo = this.unitInfo.LocNo;
    let Division = this.unitInfo.Division;
    let FirmNo = this.unitInfo.FirmNo;

    //let LocNo=this.unitInfo.LocNo;


    //this.http.get('/api/outstandings/')
    //console.log("userInfo-----",this.unitInfo.Code);

    this.http.get('/api/outstandings/' + '"' + Code + '"/"' + LocNo + '"/"' + Division + '"/"' + FirmNo + '"')

      .subscribe(response => {
        console.log("response-------", response);
        this.dataBinging = response['outstanding'];
        this.outstanding_summary = response['outstanding_summary'];

        // this.outstanding = this.dataBinging.map((item, i) => ({id: i + 1, ...item})).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);;
        // console.log(this.outstanding , 'this.outstanding ');
        if (response) {
          this.collectionSize = this.dataBinging.length;
          this.outstandingData();
        }

      })


  }

  outstandingData() {
    this.outstanding = this.dataBinging
      .map((item, i) => ({ id: i + 1, ...item }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

}
